import { useEffect, useState } from "react";
import AppNavBar from "../components/AppNavBar";
import Landing from "../components/home/1-landing/Landing";
import Head from "next/head";
import { GetServerSideProps } from "next";

// Dynamic Imports
import dynamic from "next/dynamic";
const Stats = dynamic(() => import("../components/home/2-stats/Stats"));
const TheProcess = dynamic(
  () => import("../components/home/3-the-process/TheProcess")
);
const Features = dynamic(
  () => import("../components/home/4-features/Features")
);
const FeatureScroll = dynamic(
  () => import("../components/home/4-features/FeatureScroll")
);
const OurProducts = dynamic(
  () => import("../components/home/5-our-products/OurProducts")
);
const Brand = dynamic(() => import("../components/home/6-brands/Brand"));
const Testimonials = dynamic(
  () => import("../components/home/7-testimonials/Testimonials")
);
const Blogs = dynamic(() => import("../components/home/8-blogs/Blogs"));
const Press = dynamic(() => import("../components/home/9-press/Press"));
const AppFooter = dynamic(() => import("../components/AppFooter"));
const ApplicationCTA = dynamic(() => import("../components/ApplicationCTA"));

interface HomeProps {
  initialNavColor: string;
}

const Home: React.FC<HomeProps> = ({ initialNavColor }) => {
  const [navColor, setNavColor] = useState(initialNavColor);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setNavColor("#002561");
      } else {
        setNavColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll(); // Set the initial color based on the current scroll position

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Gromo: Earn money online by Selling Financial Products</title>
        <meta
          name="description"
          content="Gromo is India's best real money earning app to earn extra income online without investment. Become a Gromo partner to Sell financial products & earn real money."
        />
        <meta name="keywords" content="GroMo Money Making Application" />
        <link rel="canonical" href="https://gromo.in" />
        <meta
          property="og:title"
          content="Earn money online by Selling Financial Products"
        />
        <meta
          property="og:description"
          content="Gromo is India's best real money earning app to earn extra income online without investment. Become a Gromo partner to Sell financial products & earn real money."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gromo.in" />
        <meta
          property="og:image"
          content="https://elasticbeanstalk-ap-south-1-812175807951.s3.ap-south-1.amazonaws.com/static-resources/notification-resources/notification_images/1668084562_Meta+Image-low+size.webp"
        />
        <meta
          name="facebook-domain-verification"
          content="h5cwilbbvtk9ui5zbw1dn1i47ohmj1"
        />
        <meta
          property="twitter:title"
          content="Become A Certified Financial Advisor, Earn Money up to ₹1 Lakh"
        />
        <meta
          property="twitter:description"
          content="Become a partner and make a regular income by selling financial products. Brand yourself as a certified financial advisor and launch and grow your business."
        />
        <meta property="twitter:url" content="https://gromo.in" />
      </Head>
      <AppNavBar navBgColor={navColor} />
      <Landing />
      <Stats />
      <TheProcess />
      <Features />
      <FeatureScroll />
      <OurProducts />
      <Brand />
      <Testimonials />
      <Press />
      <Blogs heading="GroMo Blogs" />
      <ApplicationCTA />
      <AppFooter />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const initialNavColor = "transparent";
  return { props: { initialNavColor } };
};

export default Home;
